<template>
    <div class="mainPage">
    <h1 class="site-header">Bowling Reservierung</h1>
    
    <div class="bowling">
      <input type="text" class="input" placeholder="Name*" v-model="this.mainBestellung.name" />
    <input type="tel" class="input" placeholder="Telefon*" v-model="this.mainBestellung.tel"/>
    <input type="date" class="input" v-model="this.mainBestellung.datum" />
    <input type="time" class="input" v-model="this.mainBestellung.start" />
    
    <select class="input" v-model="this.mainBestellung.stunden">
    <option value="0.5">30 Minuten</option>
    <option value="1">1 Stunde</option>
    <option value="1.5">1 Stunde 30 Minuten</option>
    <option value="2">2 Stunden</option>
    <option value="2.5">2 Stunden 30 Minuten</option>
    <option value="3">3 Stunden</option>
    <option value="3.5">3 Stunden 30 Minuten</option>
    <option value="4">4 Stunden</option>
    </select>
    
    <input type="number" class="input" placeholder="Personen*" v-model="this.mainBestellung.personen"/>
    <input type="text" class="input" placeholder="Notiz" v-model="this.mainBestellung.notiz"/>
    <input type="number" class="input" placeholder="Bahn" v-model="this.mainBestellung.bahn.selected"/>
    
    <input type="button" class="button" value="Ändern" @click="change()"/>
    <input type="button" class="button" value="Löschen" @click="löschen()"/>
    
    <p class="errorMsg" v-if="this.mainBestellung.error.status == true">{{ this.mainBestellung.error.msg }}</p>
    <p class="sucMsg" v-if="this.mainBestellung.error.sucStatus == true">{{ this.mainBestellung.error.sucText }}</p>    

    </div>
    
   
    
    </div>
    </template>
    
    <script>
    import { defineComponent } from '@vue/runtime-core'
    import { mapGetters, mapActions } from "vuex";
    
    export default defineComponent({
      setup() {
        
      }, data() {
        return {
      
            filter: ""
          }
        },
       components:{
       },
       computed: {
          ...mapGetters(["mainBestellung", "mainBahnen"]),
       },
       methods:{
           ...mapActions(["mflApi"]),

           löschen(){
            const data = {
            input: this.mainBestellung,
            url: "deleteBuchung",
            val: 99
          }

          this.mflApi(data);
          this.$router.push("overview");
           },
           change(){
            const data = {
            url: "changeBuchung",
            input: this.mainBestellung,
            val: 98
          }

          this.mflApi(data);
           }
             
       }
    })
    </script>
    
    <style>
    
    .bowling{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      width: 80%;
      border: solid black;
      background-color: rgb(212, 194, 88);
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
    }
    
    
    .input{
      width: 60%;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      border: solid 2px black;
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    
    .button{
      width: 40%;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      border: solid 2px black;
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
      margin-top: 15px;
      margin-bottom: 5px;
      cursor: pointer;
      background-color: black;
      color: rgb(212, 194, 88);
    }
    
    .button:hover{
      transform: scale(1.05);
      background-color: rgb(255, 255, 255);
    }
    
    .errorMsg{
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: rgb(189, 30, 30);
    }
    
    .sucMsg{
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: rgb(18, 89, 13);
    }
    
    .bahnen{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      width: 100%;
    }
    
    .bahn{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 50px;
      margin-right: 10px;
      margin-left: 10px;
      margin-top: 15px;
      margin-bottom: 15px;
      border: solid 2px black;
      border-radius: 50%;
      background-color: black;
      cursor: pointer;
      color: rgb(212, 194, 88);
    
    }
    
    .bahn:hover{
      transform: scale(1.05);
      background-color: white;
      color: black;
    }
    
    .bahnText{
      width: 100%;
      font-size: 24px;
      font-weight: bold;
    
      text-align: center;
      margin-block-start: 10px;
      margin-block-end: 10px;
    }
    
    .selected{
      background-color: rgb(117, 214, 117);
      color: white;
    }
    
    </style>