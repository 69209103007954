<template>
    <div class="mainPage">
    <h1 class="site-header">Shisha Reservierung</h1>
    
    <input type="checkbox" class="harryPotter" v-model="this.mainBestellung.harryPotter" />
    
    <div class="bowling">
    <input type="text" v-if="this.open == true" class="input" placeholder="Name*" v-model="this.mainBestellung.name" />
    <input type="tel" v-if="this.open == true" class="input" placeholder="Telefon*" v-model="this.mainBestellung.tel"/>
    <input type="date" class="input" @change="checkDate()" v-model="this.mainBestellung.datum" />
    <input type="time" v-if="this.open == true" @change="this.updateTime()" min="16:00" max="23:00" class="input" v-model="this.mainBestellung.start" />
    
    
    <input type="number" v-if="this.open == true" class="input" placeholder="Personen*" v-model="this.mainBestellung.personen"/>
    <input type="text" v-if="this.open == true" class="input" placeholder="Notiz" v-model="this.mainBestellung.notiz"/>
    
    <p class="errorMsg" v-if="this.mainBestellung.error.status == true">{{ this.mainBestellung.error.msg }}</p>
    <p class="sucMsg" v-if="this.mainBestellung.error.sucStatus == true">{{ this.mainBestellung.error.sucText }}</p>
        
    
    <input type="button" class="button" value="Buchen" @click="buchen()" v-if="this.open == true"/>
    
    
    </div>
    
    <div class="links">
      <p class="link" @click="redirect('datenschutz')">Datenschutz</p>
      <p class="link" @click="redirect('impressum')">Impressum</p>
    </div>
    <br>
    
    <h1 class="subText">Bitte beachten Sie, dass MONTAGS das Deluxe Bowling Center geschlossen ist.</h1>
    <h1 class="subText">Falls das Formular nicht funktionieren sollte, können Sie auch unter <u>+49 170 4618521</u> anrufen. Bitte verwenden Sie jedoch vorzugsweise dieses Kontaktformular.</h1>
    
    </div>
    </template>
    
    <script>
    import { defineComponent } from '@vue/runtime-core'
    import { mapGetters, mapActions } from "vuex";
    
    export default defineComponent({
      setup() {
        
      }, data() {
        return {
      
            filter: "",
            open: true,
          }
        },
       components:{
       },
       computed: {
          ...mapGetters(["mainBestellung", "mainBahnen", "mainMeta"]),
       },
       methods:{
           ...mapActions(["mflApi"]),
           redirect(target){
            this.$router.push(target) 
           },
           buchen(){
            //Algemeine Funktion bauen
            this.mainBestellung.sucStatus = false;
            this.mainBestellung.sucText = "";
            this.mainBestellung.error.status = true;
            this.mainBestellung.error.msg = "";
            
            var error = 0;
            if(this.mainBestellung.name == ""){
              error = 1;
              this.mainBestellung.error.msg = "Bitte geben Sie einen Namen an.";
            }
            else if(this.mainBestellung.tel == ""){
              error = 1;
              this.mainBestellung.error.msg = "Bitte geben Sie eine Telefonnummer an.";
            }
            else if(this.mainBestellung.date == ""){
              error = 1;
              this.mainBestellung.error.msg = "Bitte geben Sie ein Datum an.";
            }
            else if(this.mainBestellung.personen == ""){
              error = 1;
              this.mainBestellung.error.msg = "Bitte geben Sie die Anzahl der Personen an.";
            }
            else{
              this.mainBestellung.error.msg = "";
            }
    
            if(error == 1){
              this.mainBestellung.error.status = true;
            }
            else{
              this.mainBestellung.error.status = false;
    
              const data = {
                url: "bookShisha",
                val: 201
              }
    
              this.mflApi(data);
            }
    
           },
           checkDate(){
            const date = new Date(this.mainBestellung.datum); // Example date
            const dayNumber = date.getDay();
    
            if(dayNumber == 1){
              this.open = false,
              this.mainBestellung.error.status = true;
              this.mainBestellung.error.msg = "Montags ist das Deluxe Bowling Center geschlossen.";
            }
            else{
              this.open = true,
              this.mainBestellung.error.status = false;
              this.mainBestellung.error.msg = "";
            }
           },
           updateTime(){
            var time = this.mainBestellung.start
            let [hours, minutes] = time.split(':').map(Number);
            var minHour = "16:00";
    
            const date = new Date(this.mainBestellung.datum); // Example date
            const dayNumber = date.getDay();
            
            if(dayNumber == 6 || dayNumber == 7){
               minHour = "16:00";
            }
            else{
               minHour = "17:00";
            }
    
        if (time < minHour|| time > "23:00") {
          this.mainBestellung.start = minHour; // Reset input value
          return;
        }
    
    // Determine the rounded time based on the minutes value
    if (minutes < 8) {
        minutes = 0;
    } else if (minutes >= 8 && minutes <= 15) {
        minutes = 15;
    } else if (minutes >= 16 && minutes <= 20) {
        minutes = 15;
    } else if (minutes >= 21 && minutes <= 36) {
        minutes = 30;
    } else if (minutes >= 37 && minutes <= 49) {
        minutes = 45;
    } else if (minutes >= 50) {
        minutes = 0;
        hours = (hours + 1) % 24; // Increment hour, wrap around to 0 if it exceeds 23
    }
    
    // Format the time as HH:MM with leading zeros if needed
    const roundedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    this.mainBestellung.start = roundedTime;
    }
    
    
    
       },  created(){
        this.mainBestellung.error = {
            status: false,
                msg: "", //
                checked: false,
                sucStatus: false,
                sucText: ""
        }
  }
    })
    </script>
    
    <style>
    
    .bowling{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      width: 80%;
      border: solid black;
      background-color: rgb(212, 194, 88);
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
    }
    
    
    .input{
      width: 60%;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      border: solid 2px black;
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    
    .button{
      width: 40%;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      border: solid 2px black;
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
      margin-top: 15px;
      margin-bottom: 5px;
      cursor: pointer;
      background-color: black;
      color: rgb(212, 194, 88);
    }
    
    .button:hover{
      transform: scale(1.05);
      background-color: rgb(255, 255, 255);
    }
    
    .errorMsg{
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: rgb(189, 30, 30);
    }
    
    .sucMsg{
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: rgb(18, 89, 13);
    }
    
    .bahnen{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      width: 100%;
    }
    
    .bahn{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 50px;
      margin-right: 10px;
      margin-left: 10px;
      margin-top: 15px;
      margin-bottom: 15px;
      border: solid 2px black;
      border-radius: 50%;
      background-color: black;
      cursor: pointer;
      color: rgb(212, 194, 88);
    
    }
    
    .bahn:hover{
      transform: scale(1.05);
      background-color: white;
      color: black;
    }
    
    .bahnText{
      width: 100%;
      font-size: 24px;
      font-weight: bold;
    
      text-align: center;
      margin-block-start: 10px;
      margin-block-end: 10px;
    }
    
    .selected{
      background-color: rgb(117, 214, 117);
      color: white;
    }
    
    
    .links{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    }
    
    .link{
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      max-width: 50%;
      margin-left: 10px;
      margin-right: 10px;
    }
    
    .link:hover{
      text-decoration: underline;
    }
    
    .harryPotter{
      display: none;
    }
    
    
    .subText{
      width: 80%;
      text-align: center;
      font-size: 14px;
    }
    </style>