<template>
  <div class="mainPage">
  <h1 class="site-header">Bowling Reservierung</h1>
  
  <input type="checkbox" class="harryPotter" v-model="this.mainBestellung.harryPotter" />
  

  <p class="anleitung">Schritt 1: Geben Sie ihre Daten an (Name, Telefonnummer, Personen).</p>
  <p class="anleitung">Schritt 2: Geben Sie das Datum sowie die Uhrzeit und Länge der Buchung an.</p>
  <p class="anleitung">Schritt 3: Klicken Sie auf "Prüfen", um zu sehen, welche Bahn(en) zum gewünschten Zeitpunkt verfügbar sind.</p>
  <p class="anleitung">Schritt 4: Wählen Sie ihre gewünschte Bahn(en) aus und klicken Sie auf "Buchen".</p>
  <p class="anleitung">Schritt 5: Da es öfters noch Fehlbuchungen gab, prüfen Sie bitte mit dem erhaltenen Code, ob ihre Buchung erfolgreich war.</p>
  <!-- <p class="anleitung hinweis">HINWEIS: Bitte prüfen Sie mit ihrem Code unten, ob ihre Buchung erfolgreich war.</p> -->

  <div class="bowling">
  <input type="text" v-if="this.open == true" class="input" placeholder="Name*" v-model="this.mainBestellung.name" />
  <input type="tel" v-if="this.open == true" class="input" placeholder="Telefon*" v-model="this.mainBestellung.tel"/>
  <input type="date" class="input" @change="checkDate(1)" v-model="this.mainBestellung.datum" />
  <input type="time" v-if="this.open == true" @change="this.updateTime()" min="16:00" max="23:00" class="input" v-model="this.mainBestellung.start" />
  
  <select class="input" @change="updateTime()" v-if="this.open == true" v-model="this.mainBestellung.stunden">
  <option value="0.5">30 Minuten</option>
  <option value="1">1 Stunde</option>
  <option value="1.5">1 Stunde 30 Minuten</option>
  <option value="2">2 Stunden</option>
  <option value="2.5">2 Stunden 30 Minuten</option>
  <option value="3">3 Stunden</option>
  <option value="3.5">3 Stunden 30 Minuten</option>
  <option value="4">4 Stunden</option>
  </select>
  
  <input type="number" v-if="this.open == true" min="1" max ="60" class="input" placeholder="Personen*" v-model="this.mainBestellung.personen"/>
  <input type="text" v-if="this.open == true" class="input" placeholder="Notiz" v-model="this.mainBestellung.notiz"/>
  
  <input type="button" v-if="this.open == true" class="button" value="Prüfen" @click="checkInput()"/>
  
  <p class="errorMsg" v-if="this.mainBestellung.error.status == true">{{ this.mainBestellung.error.msg }}</p>
  <p class="sucMsg" v-if="this.mainBestellung.error.sucStatus == true">{{ this.mainBestellung.error.sucText }}</p>
  
  <div class="bahnen" v-if="this.mainBestellung.error.checked == true && this.open == true">
  <div :class="getClass(bahn)" v-for="bahn in this.mainBahnen" :key="bahn" @click="selectBahn(bahn)">
  <p class="bahnText">{{ bahn }}</p>
  </div>
  </div>
  
  
  <input type="button" class="button" value="Buchen" @click="buchen()" v-if="this.mainBahnen.length > 0 && this.open == true && this.mainBestellung.error.checked == true && this.mainBestellung.bahn.bahnen.length > 0"/>
  
  
  </div>
  
  <p class="subText">Bitte beachten Sie, dass MONTAGS das Deluxe Bowling Center geschlossen ist.</p>
  <p class="subText">Falls das Formular nicht funktionieren sollte, können Sie auch unter <u>+49 170 4618521</u> anrufen. Bitte verwenden Sie jedoch vorzugsweise dieses Kontaktformular.</p>
  

  <h2 class="site-header">Hier können Sie eine Buchung prüfen</h2>
  <div class="proofBooking">
    <input type="text"  class="input" placeholder="Ihr Code" v-model="this.code" />
    <input type="button" @click="proofBahn()" class="button" value="Prüfen" v-if="this.code != null && this.code != ''"/>

    <table class="codeTable" v-if="this.mainCode.res > 0 && this.code != null && this.code != ''">
      <tr>
        <th>Datum</th>
        <td>{{ this.mainCode.datum }}</td>
      </tr>
      <tr>
        <th>Name</th>
        <td>{{ this.mainCode.name }}</td>
      </tr>
      <tr>
        <th>Bahn</th>
        <td>{{ this.mainCode.bahn }}</td>
      </tr>
      <tr>
        <th>Uhrzeit</th>
        <td>{{ this.mainCode.uhrzeit }} - {{ this.mainCode.endzeit }} Uhr</td>
      </tr>
    </table>

    <p class="errorMsg" v-if="this.mainCode.res == 0">{{ this.mainCode.msg }}</p>

  </div>

  <div class="links">
    <p class="link" @click="redirect('datenschutz')">Datenschutz</p>
    <p class="link" @click="redirect('impressum')">Impressum</p>
  </div>
  <br>

  </div>
  </template>
  
  <script>
  import { defineComponent } from '@vue/runtime-core'
  import { mapGetters, mapActions } from "vuex";
  
  export default defineComponent({
    setup() {
      
    }, data() {
      return {
    
          filter: "",
          open: true,
          code: null
        }
      },
     components:{
     },
     computed: {
        ...mapGetters(["mainBestellung", "mainBahnen", "mainMeta", "mainCode"]),
     },
     methods:{
         ...mapActions(["mflApi"]),
         redirect(target){
          this.$router.push(target) 
         },
         buchen(){
          //Algemeine Funktion bauen
          this.mainBestellung.error.sucStatus = false;
          this.mainBestellung.error.sucText = "";
          this.mainBestellung.error.status = true;
          this.mainBestellung.error.msg = "";
          
          var error = 0;
          if(this.mainBestellung.name == "" || this.mainBestellung.name == null){
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie einen Namen an.";
          }
          else if(this.mainBestellung.tel == "" || this.mainBestellung.tel == null){
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie eine Telefonnummer an.";
          }
          else if(this.mainBestellung.datum == "" || this.mainBestellung.datum == null){
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie ein Datum an.";
          }
          else if(this.mainBestellung.start == "" || this.mainBestellung.start == null){
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie eine Uhrzeit an.";
          }
          else if(this.mainBestellung.stunden == "" || this.mainBestellung.stunden == null){
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie an, wie lange Sie spielen wollen.";
          }
          else if(this.mainBestellung.personen == "" || this.mainBestellung.personen < 1 || this.mainBestellung.personen == null){
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie die Anzahl der Personen an.";
          }
          else if(this.mainBestellung.bahn.bahnen.length < 1 || this.mainBestellung.bahn.bahnen.length > 10){
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie an, auf welcher Bahn oder welchen Bahnen Sie spielen möchten.";
          }
          else{
            this.mainBestellung.error.msg = "";
          }
  
          if(error == 1){
            this.mainBestellung.error.status = true;
          }
          else{
            this.mainBestellung.error.status = false;
  
            const data = {
              url: "bookBahn",
              val: 2
            }
  
            this.mflApi(data);
          }
  
         },
         checkInput(){
          var error = 0;
          
          this.mainBestellung.bahn.bahnen = [];

          this.mainBestellung.bahn.one = "bahn";
          this.mainBestellung.bahn.two = "bahn";
          this.mainBestellung.bahn.three = "bahn";
          this.mainBestellung.bahn.four = "bahn";
          this.mainBestellung.bahn.five = "bahn";
          this.mainBestellung.bahn.six = "bahn";
          this.mainBestellung.bahn.seven = "bahn";
          this.mainBestellung.bahn.eight = "bahn";
          this.mainBestellung.bahn.nine = "bahn";
          this.mainBestellung.bahn.ten = "bahn";


          console.log(this.mainBestellung.bahn.bahnen)
          this.mainBestellung.error.sucStatus = false;
          this.mainBestellung.error.sucText = "";
          this.mainBestellung.error.status = true;
          this.mainBestellung.error.msg = "";
  
          if(this.mainBestellung.name == "" || this.mainBestellung.name == null){
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie einen Namen an.";
          }
          else if(this.mainBestellung.tel == "" || this.mainBestellung.tel == null){
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie eine Telefonnummer an.";
          }
          else if(this.mainBestellung.datum == "" || this.mainBestellung.datum == null){
            console.log(this.mainBestellung.datum)
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie ein Datum an.";
          }
          else if(this.mainBestellung.start == "" || this.mainBestellung.start == null){
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie eine Uhrzeit an.";
          }
          else if(this.mainBestellung.stunden == "" || this.mainBestellung.stunden == null){
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie an, wie lange Sie spielen wollen.";
          }
          else if(this.mainBestellung.personen == "" || this.mainBestellung.personen < 1 || this.mainBestellung.personen == null){
            error = 1;
            this.mainBestellung.error.msg = "Bitte geben Sie die Anzahl der Personen an.";
          }
          else{
            this.mainBestellung.error.msg = "";
          }
  
          if(error == 1){
            this.mainBestellung.error.status = true;
          }
          else{
            this.mainBestellung.error.status = false;
            this.mainBestellung.error.checked = true;
  
            const data = {
              url: "checkBahn",
              val: 1,
              input: this.mainBestellung
            }
  
            this.mflApi(data);
          }
  
  
          
         },
         proofBahn(){

          if(this.code.length < 8 || this.code.length > 10){
            this.mainCode.msg = "Keine Buchung mit diesem Code gefunden.";
            return;
          }


          if(this.code == null || this.code == ""){
            return;
          }
          else{
            const data = {
              url: "proofBahn",
              val: 95,
              input: this.code
            }
  
            this.mflApi(data);
          }



          
         },
         toggleBahnen(bahn, val){
          if(val == 1){
            
            let index = this.mainBestellung.bahn.bahnen.indexOf(bahn);
            if (index !== -1) {
              this.mainBestellung.bahn.bahnen.splice(index, 1);
              }
          }
          else{
            this.mainBestellung.bahn.bahnen.push(bahn);
          }

          console.log(this.mainBestellung.bahn.bahnen)

         },
         selectBahn(bahn){

  
          if(bahn == 1){
            if(this.mainBestellung.bahn.one == "bahn selected"){
              this.mainBestellung.bahn.one = "bahn";
              this.toggleBahnen(bahn, 1);
            }
            else{
              this.mainBestellung.bahn.one = "bahn selected";
              this.toggleBahnen(bahn, 2);
            }
        
          }
          else if(bahn == 2){
            if(this.mainBestellung.bahn.two == "bahn selected"){
              this.mainBestellung.bahn.two = "bahn";
              this.toggleBahnen(bahn, 1);
            }
            else{
              this.mainBestellung.bahn.two = "bahn selected";
              this.toggleBahnen(bahn, 2);
            }
        
          }
          else if(bahn == 3){
            if(this.mainBestellung.bahn.three == "bahn selected"){
              this.mainBestellung.bahn.three = "bahn";
              this.toggleBahnen(bahn, 1);
            }
            else{
              this.mainBestellung.bahn.three = "bahn selected";
              this.toggleBahnen(bahn, 2);
            }
        
          }
          else if(bahn == 4){
            if(this.mainBestellung.bahn.four == "bahn selected"){
              this.mainBestellung.bahn.four = "bahn";
              this.toggleBahnen(bahn, 1);
            }
            else{
              this.mainBestellung.bahn.four = "bahn selected";
              this.toggleBahnen(bahn, 2);
            }
        
          }
          else if(bahn == 5){
            if(this.mainBestellung.bahn.five == "bahn selected"){
              this.mainBestellung.bahn.five = "bahn";
              this.toggleBahnen(bahn, 1);
            }
            else{
              this.mainBestellung.bahn.five = "bahn selected";
              this.toggleBahnen(bahn, 2);
            }
        
          }
          else if(bahn == 6){
            if(this.mainBestellung.bahn.six == "bahn selected"){
              this.mainBestellung.bahn.six = "bahn";
              this.toggleBahnen(bahn, 1);
            }
            else{
              this.mainBestellung.bahn.six = "bahn selected";
              this.toggleBahnen(bahn, 2);
            }
        
          }
          else if(bahn == 7){
            if(this.mainBestellung.bahn.seven == "bahn selected"){
              this.mainBestellung.bahn.seven = "bahn";
              this.toggleBahnen(bahn, 1);
            }
            else{
              this.mainBestellung.bahn.seven = "bahn selected";
              this.toggleBahnen(bahn, 2);
            }
        
          }
          else if(bahn == 8){
            if(this.mainBestellung.bahn.eight == "bahn selected"){
              this.mainBestellung.bahn.eight = "bahn";
              this.toggleBahnen(bahn, 1);
            }
            else{
              this.mainBestellung.bahn.eight = "bahn selected";
              this.toggleBahnen(bahn, 2);
            }
        
          }
          else if(bahn == 9){
            if(this.mainBestellung.bahn.nine == "bahn selected"){
              this.mainBestellung.bahn.nine = "bahn";
              this.toggleBahnen(bahn, 1);
            }
            else{
              this.mainBestellung.bahn.nine = "bahn selected";
              this.toggleBahnen(bahn, 2);
            }
        
          }
          else if(bahn == 10){
            if(this.mainBestellung.bahn.ten == "bahn selected"){
              this.mainBestellung.bahn.ten = "bahn";
              this.toggleBahnen(bahn, 1);
            }
            else{
              this.mainBestellung.bahn.ten = "bahn selected";
              this.toggleBahnen(bahn, 2);
            }
        
          }
  
         },
        //  selectBahn(bahn){
        //   this.mainBestellung.bahn.selected = bahn;
  
        //   this.mainBestellung.bahn.one = "bahn";
        //   this.mainBestellung.bahn.two = "bahn";
        //   this.mainBestellung.bahn.three = "bahn";
        //   this.mainBestellung.bahn.four = "bahn";
        //   this.mainBestellung.bahn.five = "bahn";
        //   this.mainBestellung.bahn.six = "bahn";
        //   this.mainBestellung.bahn.seven = "bahn";
        //   this.mainBestellung.bahn.eight = "bahn";
        //   this.mainBestellung.bahn.nine = "bahn";
        //   this.mainBestellung.bahn.ten = "bahn";
  
        //   if(bahn == 1){
        //     this.mainBestellung.bahn.one = "bahn selected";
        //   }
        //   else if(bahn == 2){
        //     this.mainBestellung.bahn.two = "bahn selected";
        //   }
        //   else if(bahn == 3){
        //     this.mainBestellung.bahn.three = "bahn selected";
        //   }
        //   else if(bahn == 4){
        //     this.mainBestellung.bahn.four = "bahn selected";
        //   }
        //   else if(bahn == 5){
        //     this.mainBestellung.bahn.five = "bahn selected";
        //   }
        //   else if(bahn == 6){
        //     this.mainBestellung.bahn.six = "bahn selected";
        //   }
        //   else if(bahn == 7){
        //     this.mainBestellung.bahn.seven = "bahn selected";
        //   }
        //   else if(bahn == 8){
        //     this.mainBestellung.bahn.eight = "bahn selected";
        //   }
        //   else if(bahn == 9){
        //     this.mainBestellung.bahn.nine = "bahn selected";
        //   }
        //   else if(bahn == 10){
        //     this.mainBestellung.bahn.ten = "bahn selected";
        //   }
        //  },
  
         getClass(bahn){
          if(bahn == 1){
            return this.mainBestellung.bahn.one;
          }
          else if(bahn == 2){
            return this.mainBestellung.bahn.two;
          }
          else if(bahn == 3){
            return this.mainBestellung.bahn.three;
          }
          else if(bahn == 4){
            return this.mainBestellung.bahn.four;
          }
          else if(bahn == 5){
            return this.mainBestellung.bahn.five;
          }
          else if(bahn == 6){
            return this.mainBestellung.bahn.six;
          }
          else if(bahn == 7){
            return this.mainBestellung.bahn.seven;
          }
          else if(bahn == 8){
            return this.mainBestellung.bahn.eight;
          }
          else if(bahn == 9){
            return this.mainBestellung.bahn.nine;
          }
          else if(bahn == 10){
            return this.mainBestellung.bahn.ten;
          }
         },
         checkDate(val){

          const date = new Date(this.mainBestellung.datum); // Example date
          const dayNumber = date.getDay();

          this.mainBestellung.start = null;

          if(val == 1){
            this.mainBestellung.error.checked = false;
            this.mainBestellung.bahn.selected = "";
          }
  
          if(dayNumber == 1){
            this.open = false,
            this.mainBestellung.error.status = true;
            this.mainBestellung.error.msg = "Montags ist das Deluxe Bowling Center geschlossen.";
          }
          else{
            this.open = true,
            this.mainBestellung.error.status = false;
            this.mainBestellung.error.msg = "";
          }


         },
         updateTime(){
          var time = this.mainBestellung.start
          let [hours, minutes] = time.split(':').map(Number);
          var minHour = "16:00";
  
          const date = new Date(this.mainBestellung.datum); // Example date
          const dayNumber = date.getDay();
        
          if(dayNumber >= 5 || dayNumber == 0){
             minHour = "16:00";
          }
          else{
             minHour = "17:00";
          }
  
      if (time < minHour || time > "23:45") {
        this.mainBestellung.start = minHour; // Reset input value
        return;
      }
  
  // Determine the rounded time based on the minutes value
  if (minutes < 8) {
      minutes = 0;
  } else if (minutes >= 8 && minutes <= 15) {
      minutes = 15;
  } else if (minutes >= 16 && minutes <= 20) {
      minutes = 15;
  } else if (minutes >= 21 && minutes <= 36) {
      minutes = 30;
  } else if (minutes >= 37 && minutes <= 49) {
      minutes = 45;
  } else if (minutes >= 50) {
      minutes = 0;
      hours = (hours + 1) % 24; // Increment hour, wrap around to 0 if it exceeds 23
  }
  
  this.checkDate(1);

  // Format the time as HH:MM with leading zeros if needed
  const roundedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  this.mainBestellung.start = roundedTime;
  }
  
  
  
     },  created(){
      this.mainBestellung.error = {
          status: false,
              msg: "", //
              checked: false,
              sucStatus: false,
              sucText: ""
      }
}
  })
  </script>
  
  <style>
  
  .bowling{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
    border: solid black;
    background-color: rgb(212, 194, 88);
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  
  .input{
    width: 60%;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    border: solid 2px black;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .button{
    width: 40%;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    border: solid 2px black;
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
    border-radius: 5px;
    margin-top: 15px;
    margin-bottom: 5px;
    cursor: pointer;
    background-color: black;
    color: rgb(212, 194, 88);
  }
  
  .button:hover{
    transform: scale(1.05);
    background-color: rgb(255, 255, 255);
  }
  
  .errorMsg{
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: rgb(189, 30, 30);
  }
  
  .sucMsg{
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    color: rgb(18, 89, 13);
  }
  
  .bahnen{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    width: 100%;
  }
  
  .bahn{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 50px;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 15px;
    margin-bottom: 15px;
    border: solid 2px black;
    border-radius: 50%;
    background-color: black;
    cursor: pointer;
    color: rgb(212, 194, 88);
  
  }
  
  .bahn:hover{
    transform: scale(1.05);
    background-color: white;
    color: black;
  }
  
  .bahnText{
    width: 100%;
    font-size: 24px;
    font-weight: bold;
  
    text-align: center;
    margin-block-start: 10px;
    margin-block-end: 10px;
  }
  
  .selected{
    background-color: rgb(117, 214, 117);
    color: white;
  }
  
  
  .links{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
  }
  
  .link{
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    max-width: 50%;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .link:hover{
    text-decoration: underline;
  }
  
  .harryPotter{
    display: none;
  }
  
  
  .subText{
    width: 80%;
    text-align: center;
    font-size: 14px;
  }


  .proofBooking{
    width: 80%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .codeTable{
    text-align: center;
    width: 80%;
  }

  .codeTable th, td{
    width: 50%;
  }

  .anleitung{
    width: 80%;
    font-size: 12;
    font-weight: bold;
    text-align: center;
    margin-block-start: 2px;
    margin-block-end: 2px;
  }

  .hinweis{
    color: rgb(227, 48, 48);
  }
  </style>