<template>
  <div class="window" v-if="this.mainWindow.main == true">
    <buchung />
  </div>
</template>

<script>
import { defineComponent } from '@vue/runtime-core'
import buchung from "@/components/buchung";
import { mapGetters, mapActions } from "vuex";

export default defineComponent({
  setup() {
    
  },
   components:{
    buchung
   },
   computed: {
     ...mapGetters(["mainBestellung", "mainWindow"]),
   },
   methods:{
     ...mapActions(["mflApi"]),
   
   },
  created(){
 
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.window{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100vw;
  height: 100vh;

  z-index: 999;
}


</style>
