<template>
    <div class="mainPage" >
        <h1 class="site-header">Bowling Reservierungen</h1>

<!-- ----------------------------------------------------------------------------------------
Login -->
        <div class="section" v-if="this.mainUser.loggedIn == false">
  <h2 class="header1">Login</h2>

  <input type="text" class="input" placeholder="Username" v-model="this.user.name" />
  <input type="password"  class="input" placeholder="Password" v-model="this.user.pw" />

  
<input type="button" class="button" value="LOGIN" @click="login()"/>
</div> 
<!-- ----------------------------------------------------------------------------------------  -->
<h1 class="subText">Die Verschiebungs Funktion musste erstmal rausgenommen werden.</h1>
 
    <input    v-if="this.mainUser.loggedIn == true" type="date" class="input" v-model="this.mainMeta.datum" @change="updateList()"/>
   
    <div class="mainBahnen"    v-if="this.mainUser.loggedIn == true">

        <div class="singleBahn" v-for="bahn in this.mainMeta.bahnen" :key="bahn">
            <!-- this.mainMeta.bahnen -->
            


            <h2 class="bahnHeader">{{ bahn.bahn }}</h2>

            <!-- <div class="buchung">
                <table class="buchungTableHeader">
                    <tr>
                        <td>{{ bahn.bahn }}</td>
                    </tr>
                </table>
            </div> -->

            <p class="add" @click="addBuchung(bahn.bahn)">+</p>
            <!-- <div class="buchung">
                <table class="buchungTable">
                    <tr>
                        <td @click="addBuchung(bahn.bahn)">+</td>
                    </tr>
                </table>
            </div> -->


            <div class="buchung" v-for="buchung in bahn.list" :key="buchung" >
                <table class="buchungTable" @click="redirect(buchung)">
                    <tr>
                        <td>{{ buchung.name }}</td>
                    </tr>
                    <tr>
                        <td>{{ buchung.personen }}</td>
                    </tr>
                    <tr>
                        <td>Tel: {{ buchung.tel }}</td>
                    </tr>
                    <tr>
                        <td>{{ buchung.uhrzeit }} - {{ buchung.endzeit }}</td>
                    </tr>

                    <tr>
                        <td>{{ buchung.notiz }}</td>
                    </tr>
                </table>

                
            <!-- <div class="slideDiv">
                <p class="slideIcon" @click="slide(1, buchung)">&lt;</p>
                <p class="slideIcon" @click="slide(2, buchung)">&gt;</p>
            </div> -->
            
            </div>



        </div>
    </div>
    </div>
    </template>
    
    <script>
    import { defineComponent } from '@vue/runtime-core'
    import { mapGetters, mapActions } from "vuex";
    
    export default defineComponent({
      setup() {
        
      }, data() {
        return {
            user: {
              name: null,
              pw: null,
            },
         bahnen:[
            {bahn: 1, list: [{id: "11", bahn:1,  datum: "2024-11-11", stunden: 2 , name: "Hitler", tel: "183819", uhrzeit: "21:00", endzeit: "23:00", personen: 4, notiz: ""},
            {id: "22", bahn: 1, name: "Broly", datum: "2024-11-11",  stunden: 2, tel: "183819", uhrzeit: "16:00", endzeit: "18:00", personen: 2, notiz: "Geburtstag"},


            ]},
            {bahn: 2, list: []},
            {bahn: 3, list: []},
            {bahn: 4, list: []},
            {bahn: 5, list: []},
            {bahn: 6, list: []},
            {bahn: 7, list: []},
            {bahn: 8, list: []},
            {bahn: 9, list: []},
            {bahn: 10, list: []},
         ]
          }
        },
       components:{
       },
       computed: {
          ...mapGetters(["mainBestellung", "mainBahnen", "mainMeta", "mainUser", "mainWindow"]),
       },
       methods:{
           ...mapActions(["mflApi"]),
        redirect(buchung){
            this.mainBestellung.name = buchung.name
            this.mainBestellung.datum = buchung.datum
            this.mainBestellung.tel = buchung.tel
            this.mainBestellung.start = buchung.uhrzeit
            this.mainBestellung.stunden = buchung.stunden
            this.mainBestellung.personen = buchung.personen
            this.mainBestellung.notiz = buchung.notiz
            this.mainBestellung.id = buchung.id
            this.mainBestellung.bahn.selected = buchung.bahn
            this.$router.push("buchung")
            console.log(this.mainBestellung)
        },
        getBahnen(val){
            if(val == 1){
                return [
                    this.mainMeta.bahnen[0],  this.mainMeta.bahnen[1],  this.mainMeta.bahnen[2],  this.mainMeta.bahnen[3],  this.mainMeta.bahnen[4], 
                ]
            }
            else{
                return [
                    this.mainMeta.bahnen[5],  this.mainMeta.bahnen[6],  this.mainMeta.bahnen[7],  this.mainMeta.bahnen[8],  this.mainMeta.bahnen[9], 
                ]
            }
        },
        updateList(){
            const data = {
            url: "getBahnen",
            val: 101
          }

          this.mflApi(data);
        },
        addBuchung(bahn){
            this.mainWindow.main = true;
            this.mainBestellung.bahn.selected = bahn;
            console.log(bahn);
        },
        slide(val, buchung){

            var bahn = buchung.bahn
            var newBahn = parseInt(bahn);

            if(val == 1){
                newBahn = newBahn - 1;
            }
            else{
                newBahn = newBahn +1;
            }

            console.log(newBahn);

            if(newBahn < 1 || newBahn > 10){
                return;
            }
            else{
                var obj = {
                    buchung: buchung,
                    newBahn: newBahn
                }

                const data = {
  url: "slideBahn",
  input: obj,
  val: 96
}

this.mflApi(data);

            }


            
        },
        login(){

if(this.user.name == null || this.user.name == "" || this.user.pw == null || this.user.pw == ""){
  alert("Bitte Username und Passwort eingeben!");
  return;
}
else{
  const data = {
  url: "login",
  input: this.user,
  val: 999
}

this.mflApi(data);
}


}
    
       },
       created(){
             const data = {
            url: "checkCookie",
            val: 998
          }

          this.mflApi(data);


     
       // console.log(this.bahnen);
       // console.log(this.mainMeta.bahnen);
      //  this.bahnen = this.mainMeta.bahnen;
       }
    })
    </script>
    
    <style>
    
 .mainBahnen{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 15px;
 }


 .singleBahn{
    width: 125px;
    background-color: black;
    /* background-color: rgb(129, 120, 67); */
    box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
    margin-left: 15px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
 }

 .buchung{
width: 100px;
background-color: rgb(129, 120, 67);

box-shadow: 2px 2px 4px rgb(129, 120, 67);
margin-top: 5px;
margin-bottom: 5px;
 }



 .buchung:hover{
    transform: scale(1.05);
    cursor: pointer;
    background-color: rgb(162, 154, 108);
 }

 .buchungTable{
    color: white;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
 }

 .buchungTableHeader{
    width: 100%;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
 }


 .slideDiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 100%;
 }

 .slideIcon{
    font-size: 15px;
    font-weight: bold;
    cursor: pointer;
    color: white;
    margin-left: 5px;
    margin-right: 5px;
    margin-block-end: 0px;
    margin-block-start: 0px;
 }

 .slideIcon:hover{
    transform: scale(1.1);
    color: black;
 }

 .add{
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(129, 120, 67);
    font-size: 20px;
    font-weight: bold;
    margin-block-start: 5px;
    margin-block-end: 5px;
    border: solid 2px rgb(129, 120, 67);
    width: 40%;
    text-align: center;
    border-radius: 15%;
 }

 .bahnHeader{
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(129, 120, 67);
    font-size: 26px;
    font-weight: bold;
    margin-block-start: 5px;
    margin-block-end: 5px;
    width: 100%;
    text-align: center;
 }

 .add:hover{
    transform: scale(1.05);
    cursor: pointer;
    background-color: rgb(129, 120, 67);
    color: black;
 }
    
    </style>