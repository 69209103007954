<template>
    <div class="mainBuchung">
    
    <div class="bowling">
      <input v-if="this.open == true" type="text" class="input" placeholder="Name*" v-model="this.mainBestellung.name" />
    <input v-if="this.open == true" type="tel" class="input" placeholder="Telefon*" v-model="this.mainBestellung.tel" />
    <input type="date" class="input" v-model="this.mainBestellung.datum" @change="checkDate(1)" />
    <input v-if="this.open == true" type="time" @change="this.updateTime()" class="input" v-model="this.mainBestellung.start" />
    
    <select class="input" v-model="this.mainBestellung.stunden" v-if="this.open == true">
    <option value="0.5">30 Minuten</option>
    <option value="1">1 Stunde</option>
    <option value="1.5">1 Stunde 30 Minuten</option>
    <option value="2">2 Stunden</option>
    <option value="2.5">2 Stunden 30 Minuten</option>
    <option value="3">3 Stunden</option>
    <option value="3.5">3 Stunden 30 Minuten</option>
    <option value="4">4 Stunden</option>
    </select>
    
    <input type="number" class="input" placeholder="Personen*" v-model="this.mainBestellung.personen" v-if="this.open == true" />
    <input type="text" class="input" placeholder="Notiz" v-model="this.mainBestellung.notiz" v-if="this.open == true" />
    <input type="number" class="input" placeholder="Bahn" v-model="this.mainBestellung.bahn.selected" v-if="this.open == true" />
    
    <input type="button" class="button" value="Buchen" @click="buchenWindow()" v-if="this.open == true" />
    <input type="button" class="button" value="Schließen" @click="close()"/>
    
    <p class="errorMsg" v-if="this.mainBestellung.error.status == true">{{ this.mainBestellung.error.msg }}</p>
    <p class="sucMsg" v-if="this.mainBestellung.error.sucStatus == true">{{ this.mainBestellung.error.sucText }}</p>    

    </div>
    
   
    
    </div>
    </template>
    
    <script>
    import { defineComponent } from '@vue/runtime-core'
    import { mapGetters, mapActions } from "vuex";
    
    export default defineComponent({
      setup() {
        
      }, data() {
        return {
            open:true,
            filter: ""
          }
        },
       components:{
       },
       computed: {
          ...mapGetters(["mainBestellung", "mainBahnen", "mainWindow", "mainMeta"]),
       },
       methods:{
           ...mapActions(["mflApi"]),

           close(){
            this.mainWindow.main = false;

            this.mainBestellung.name = "";
            this.mainBestellung.datum = "";
            this.mainBestellung.tel = "";
            this.mainBestellung.notiz = "";
            this.mainBestellung.start = "";
            this.mainBestellung.personen = "";
            this.mainBestellung.stunden = "";
            this.mainBestellung.bahn.selected = "";
          
           },
           checkDate(val){
          const date = new Date(this.mainBestellung.datum); // Example date
          const dayNumber = date.getDay();

          if(val == 1){
            this.mainBestellung.error.checked = false;
            this.mainBestellung.bahn.selected = "";
          }
  
          if(dayNumber == 1){
            this.open = false,
            this.mainBestellung.error.status = true;
            this.mainBestellung.error.msg = "Montags ist das Deluxe Bowling Center geschlossen.";
          }
          else{
            this.open = true,
            this.mainBestellung.error.status = false;
            this.mainBestellung.error.msg = "";
          }
         },
         buchenWindow(){
            const data = {
            url: "addExtraBuchung",
            input: this.mainBestellung,
            val: 97
          }

          this.mflApi(data);
           },

           updateTime(){
            var time = this.mainBestellung.start
            let [hours, minutes] = time.split(':').map(Number);
            var minHour = "16:00";
    
            const date = new Date(this.mainBestellung.datum); // Example date
            const dayNumber = date.getDay();
            
            if(dayNumber >= 5){
               minHour = "16:00";
            }
            else{
               minHour = "17:00";
            }
    
        if (time < minHour|| time > "23:00") {
          this.mainBestellung.start = minHour; // Reset input value
          return;
        }
    
    // Determine the rounded time based on the minutes value
    if (minutes < 8) {
        minutes = 0;
    } else if (minutes >= 8 && minutes <= 15) {
        minutes = 15;
    } else if (minutes >= 16 && minutes <= 20) {
        minutes = 15;
    } else if (minutes >= 21 && minutes <= 36) {
        minutes = 30;
    } else if (minutes >= 37 && minutes <= 49) {
        minutes = 45;
    } else if (minutes >= 50) {
        minutes = 0;
        hours = (hours + 1) % 24; // Increment hour, wrap around to 0 if it exceeds 23
    }
    
    // Format the time as HH:MM with leading zeros if needed
    const roundedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    this.mainBestellung.start = roundedTime;
    }
    
    
             
       },
       created(){
        this.mainBestellung.datum = this.mainMeta.datum;
        this.mainBestellung.error = {
            status: false,
                msg: "", //
                checked: false,
                sucStatus: false,
                sucText: ""
        }
       }
    })
    </script>
    
    <style>

    .mainBuchung{
        width: 80%;
        z-index: 1000;
        display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
    
    }
    
    .bowling{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      width: 80%;
      border: solid black;
      background-color: rgb(212, 194, 88);
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
      z-index: 1001;
    }
    
    
    .input{
      width: 60%;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      border: solid 2px black;
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    
    .button{
      width: 40%;
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      border: solid 2px black;
      box-shadow: 6px 6px 10px rgba(0, 0, 0, 0.2), -3px -3px 8px rgba(255, 255, 255, 0.6);
      border-radius: 5px;
      margin-top: 15px;
      margin-bottom: 5px;
      cursor: pointer;
      background-color: black;
      color: rgb(212, 194, 88);
    }
    
    .button:hover{
      transform: scale(1.05);
      background-color: rgb(255, 255, 255);
    }
    
    .errorMsg{
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: rgb(189, 30, 30);
    }
    
    .sucMsg{
      width: 100%;
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      color: rgb(18, 89, 13);
    }
    
    .bahnen{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      width: 100%;
    }
    
    .bahn{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 50px;
      margin-right: 10px;
      margin-left: 10px;
      margin-top: 15px;
      margin-bottom: 15px;
      border: solid 2px black;
      border-radius: 50%;
      background-color: black;
      cursor: pointer;
      color: rgb(212, 194, 88);
    
    }
    
    .bahn:hover{
      transform: scale(1.05);
      background-color: white;
      color: black;
    }
    
    .bahnText{
      width: 100%;
      font-size: 24px;
      font-weight: bold;
    
      text-align: center;
      margin-block-start: 10px;
      margin-block-end: 10px;
    }
    
    .selected{
      background-color: rgb(117, 214, 117);
      color: white;
    }
    
    </style>