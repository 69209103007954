import { createRouter, createWebHashHistory } from 'vue-router'
import home from '../views/home.vue'
import overview from '../views/overview.vue'
import buchung from '../views/buchung.vue'
import datenschutz from '../views/datenschutz.vue'
import impressum from '../views/impressum.vue'
import bowling from '../views/bowling.vue'
import shisha from '../views/shisha.vue'
import tanzen from '../views/tanzen.vue'
import event from '../views/event.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: home
    },
    {
        path: '/overview',
        name: 'overview',
        component: overview
    },
    {
        path: '/buchung',
        name: 'buchung',
        component: buchung
    },
    {
        path: '/datenschutz',
        name: 'datenschutz',
        component: datenschutz
    },
    {
        path: '/impressum',
        name: 'impressum',
        component: impressum
    },
    {
        path: '/bowling',
        name: 'bowling',
        component: bowling
    },
    {
        path: '/shisha',
        name: 'shisha',
        component: shisha
    },
    {
        path: '/tanzen',
        name: 'tanzen',
        component: tanzen
    },
    {
        path: '/event',
        name: 'event',
        component: event
    }

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router